export const ADD_ALERT = 'ADD_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export function addAlert(msgType, message) {
    return {
        type: ADD_ALERT,
        msgType,
        message
    }
}

export function dismissAlert(idx) {
    return {
        type: DISMISS_ALERT,
        idx
    }
}

export function clearAlerts() {
    return {
        type: CLEAR_ALERTS
    }
}
import { call, put, takeLatest } from "redux-saga/effects";

import { api, transformApiException } from "../api";
import { addAlert } from "../actions/alerts";
import {
    videoTemplatesFetchSucceeded,
    VIDEO_TEMPLATES_FETCH_REQUESTED,
} from "../actions/videoTemplates";


function apiFetchVideoTemplates() {
    return api.get('/api/video-templates', null);
}

function* fetchVideoTemplates(action) {
    try {
        const videoTemplates = yield call(apiFetchVideoTemplates);
        yield put(videoTemplatesFetchSucceeded(videoTemplates));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function* videoTemplatesSaga() {
    yield takeLatest(VIDEO_TEMPLATES_FETCH_REQUESTED, fetchVideoTemplates);
}

export default videoTemplatesSaga;

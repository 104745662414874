import { parseISO } from 'date-fns';
import { call, put, takeLatest } from "redux-saga/effects";

import { api, transformApiException } from "../api";
import { addAlert } from "../actions/alerts";
import {
    todaysVisitorsFetchSucceeded,
    visitorAddImageSucceeded,
    visitorRemoveImage,
    visitorCreateSucceeded,
    visitorCreateVideoFailed,
    visitorCreateVideoSucceeded,
    visitorDeleteSucceeded,
    visitorsFetchSucceeded,
    visitorUpdateSucceeded,
    VISITORS_FETCH_REQUESTED,
    TODAYS_VISITORS_FETCH_REQUESTED,
    VISITOR_CREATE_VIDEO,
    VISITOR_CREATE,
    VISITOR_DELETE,
    VISITOR_UPDATE,
    VISITOR_ADD_IMAGE
} from "../actions/visitors";

function cleanVisitor(visitor) {
    if (visitor.visitDateAndTime) {
        visitor.visitDateAndTime = parseISO(visitor.visitDateAndTime);
    }

    return visitor;
}

function cleanVisitors(visitors) {
    for (let visitor of visitors) {
        visitor = cleanVisitor(visitor);
    }

    return visitors;
}

function apiFetchVisitors() {
    return api.get('/api/visitors', null);
}

function* fetchVisitors(action) {
    try {
        let visitors = yield call(apiFetchVisitors);

        visitors = cleanVisitors(visitors);

        yield put(visitorsFetchSucceeded(visitors));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiFetchTodaysVisitors() {
    return api.get('/api/visitors/today', null);
}

function* fetchTodaysVisitors(action) {
    try {
        let todaysVisitors = yield call(apiFetchTodaysVisitors);

        todaysVisitors = cleanVisitors(todaysVisitors);

        yield put(todaysVisitorsFetchSucceeded(todaysVisitors));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiCreateVisitor(visitor) {
    return api.post('/api/visitors', visitor);
}

function* createVisitor(action) {
    try {
        let visitor = yield call(apiCreateVisitor, action.visitor);

        visitor = cleanVisitor(visitor);

        yield put(visitorCreateSucceeded(visitor));
    } catch (e) {
        if (e.request.status === 400) {
            let responseJson = JSON.parse(e.request.response);
            yield put(addAlert('danger', responseJson.errors));
        } else {
            yield put(addAlert('danger', transformApiException(e)));
        }
    }
}

function apiUpdateVisitor(visitor) {
    return api.post('/api/visitors/' + visitor.id, visitor);
}

function* updateVisitor(action) {
    try {
        let visitor = yield call(apiUpdateVisitor, action.visitor);

        visitor = cleanVisitor(visitor);

        yield put(visitorUpdateSucceeded(visitor));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiDeleteVisitor(visitor) {
    return api.delete('/api/visitors/' + visitor.id);
}

function* deleteVisitor(action) {
    try {
        yield call(apiDeleteVisitor, action.visitor);
        yield put(visitorDeleteSucceeded(action.visitor));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiAddVisitorImage(visitorId, imageNumber, imageFile, progressUpdate) {
    const formData = new FormData();
    formData.append('image' + imageNumber, imageFile);

    return api.post('/api/visitors/' + visitorId + '/add-image', formData, {
        onUploadProgress: (progressEvent) => {
            let percent = (progressEvent.loaded / progressEvent.total) * 100;
            progressUpdate(percent);
        }
    });
}

function* addVisitorImage(action) {
    try {
        yield put(visitorRemoveImage(action.visitorId, action.imageNumber));

        let visitor = yield call(apiAddVisitorImage, action.visitorId, action.imageNumber, action.imageFile, action.progressUpdate);

        visitor = cleanVisitor(visitor);

        yield put(visitorAddImageSucceeded(visitor));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiCreateVisitorVideo(visitorId) {
    return api.post('/api/visitors/' + visitorId + '/create-video', null);
}

function* createVisitorVideo(action) {
    try {
        let visitor = yield call(apiCreateVisitorVideo, action.visitorId);

        visitor = cleanVisitor(visitor);

        yield put(visitorCreateVideoSucceeded(visitor));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
        yield put(visitorCreateVideoFailed(action.visitorId));
    }
}

function* visitorsSaga() {
    yield takeLatest(VISITORS_FETCH_REQUESTED, fetchVisitors);
    yield takeLatest(TODAYS_VISITORS_FETCH_REQUESTED, fetchTodaysVisitors);
    yield takeLatest(VISITOR_CREATE, createVisitor);
    yield takeLatest(VISITOR_UPDATE, updateVisitor);
    yield takeLatest(VISITOR_DELETE, deleteVisitor);
    yield takeLatest(VISITOR_ADD_IMAGE, addVisitorImage);
    yield takeLatest(VISITOR_CREATE_VIDEO, createVisitorVideo);
}

export default visitorsSaga;

import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from "reactstrap";

import VizVidLogo from "../Logo";
import AlertsContainer from "../Alerts";
import { LoginContainer } from "../Login";
import TodaysVisitorsContainer from "../TodaysVisitors";
import Settings from "../Settings";
import UsersContainer from "../Users";
import { VisitorsContainer } from "../Visitors";
import VideoTemplatesContainer from "../VideoTemplates";
import Analytics from "../Analytics";
import { logout } from "../../actions/auth";

class App extends Component {
    constructor() {
        super();

        this.state = {
            isOpen: false,
        };

        this.toggle = this.toggle.bind(this);
        this.closeNavBar = this.closeNavBar.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    closeNavBar() {
        this.setState({
            isOpen: false,
        });
    }

    handleLogOut(e) {
        e.preventDefault();
        this.props.dispatch(logout());
    }

    render() {
        if (this.props.currentUser) {
            return (
                <Router basename="/app">
                    <div>
                        <AlertsContainer />
                        <Navbar
                            dark
                            fixed="top"
                            expand="lg"
                            className="navbar-custom"
                        >
                            <NavbarToggler onClick={this.toggle} />
                            <NavLink
                                tag={Link}
                                to="/"
                                className="brand"
                                onClick={this.closeNavBar}
                            >
                                <VizVidLogo
                                    title="Home"
                                    ariaHidden={true}
                                    tabIndex={-1}
                                />
                            </NavLink>
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <div className="navbar-nav me-auto">
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            to="/visitors"
                                            onClick={this.closeNavBar}
                                        >
                                            Visitors
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            to="/campaigns"
                                            onClick={this.closeNavBar}
                                        >
                                            Campaigns
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            to="/analytics"
                                            onClick={this.closeNavBar}
                                        >
                                            Analytics
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            to="/users"
                                            onClick={this.closeNavBar}
                                        >
                                            Users
                                        </NavLink>
                                    </NavItem>
                                </div>
                                <div className="navbar-nav">
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle caret nav>
                                            {this.props.currentUser.email}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                tag={Link}
                                                to="/settings"
                                            >
                                                Settings
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={this.handleLogOut}
                                            >
                                                Log out
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </Collapse>
                        </Navbar>
                        <section className="navbar-padding">
                            <div className="container-fluid app-padding">
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<TodaysVisitorsContainer />}
                                    />
                                    <Route
                                        path="/visitors"
                                        element={<VisitorsContainer />}
                                    />
                                    <Route
                                        path="/campaigns"
                                        element={<VideoTemplatesContainer />}
                                    />
                                    <Route
                                        path="/analytics"
                                        element={<Analytics />}
                                    />
                                    <Route
                                        path="/users"
                                        element={<UsersContainer />}
                                    />
                                    <Route
                                        path="/settings"
                                        element={<Settings />}
                                    />
                                </Routes>
                            </div>
                        </section>
                    </div>
                </Router>
            );
        } else {
            return (
                <Router basename="/app">
                    <div className="login">
                        <AlertsContainer />
                        <Routes>
                            <Route path="/" element={<LoginContainer />} />
                            <Route
                                path="/reset-password/:token"
                                element={<LoginContainer />}
                            />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </div>
                </Router>
            );
        }
    }
}

export default App;

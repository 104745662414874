import { connect } from 'react-redux';
import { Visitors } from './Visitors';

const mapStateToProps = state => {
  return {
    visitors: state.visitors,
    videoTemplates: state.videoTemplates,
  }
};

export const VisitorsContainer = connect(
  mapStateToProps
)(Visitors);

import React, { Component } from 'react';

class Settings extends Component {
    render() {
        return (
            <h1>Settings</h1>
        );
    }
}

export default Settings;

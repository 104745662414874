import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useWindowSize } from "../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { analyticsFetchRequested } from "../../actions/analytics";
import { videoTemplatesFetchRequested } from "../../actions/videoTemplates";
import {
    BarChart,
    Bar,
    Cell,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";

function Analytics() {
    const dispatch = useDispatch();
    const analytics = useSelector((state) => state.analytics);
    const videoTemplates = useSelector((state) => state.videoTemplates);
    const [legend, setLegend] = useState("Viewing analytics for all campaigns");
    const windowSize = useWindowSize();

    useEffect(() => {
        dispatch(analyticsFetchRequested(""));
        dispatch(videoTemplatesFetchRequested());
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let videoTemplateId = e.target.videoTemplateId.value;
        let start = e.target.start.value;
        let end = e.target.end.value;
        let queryString = `videoTemplateId=${videoTemplateId}&start=${start}&end=${end}`;
        dispatch(analyticsFetchRequested(queryString));
        setLegend(
            `Viewing analytics for ${
                videoTemplateId
                    ? videoTemplates.find(
                          (videoTemplate) =>
                              videoTemplate.id === videoTemplateId
                      ).name
                    : "all campaigns"
            }${start ? " from " + start : ""}${end ? " to " + end : ""}`
        );
    };

    const colors = [
        "#00c4ff",
        "#ffd700",
        "#7a00ff",
        "#2b005b",
        "#15A3FF",
        "#8e5800",
        "#827f85",
        "#cedbea",
    ];

    return (
        <div>
            <div className="page-header">
                <h1>Analytics</h1>
                <p>
                    Here's a summary on how your campaigns are doing. View
                    analytics by campaign and/or a date range.
                </p>
            </div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} lg={4}>
                        <FormGroup>
                            <Label for="videoTemplateId">Campaign</Label>
                            <Input
                                type="select"
                                name="videoTemplateId"
                                id="videoTemplateId"
                            >
                                <option value="">All</option>
                                {videoTemplates.map((template, idx) => {
                                    return (
                                        <option key={idx} value={template.id}>
                                            {template.name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={4}>
                        <FormGroup>
                            <Label for="start">Date Range Start</Label>
                            <Input type="date" name="start" id="start" />
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={4}>
                        <FormGroup>
                            <Label for="end">Date Range End</Label>
                            <Input type="date" name="end" id="end" />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <Button className="mb-2">View Analytics</Button>
                    </Col>
                </Row>
            </Form>
            <h2 className="mb-4">{legend}</h2>
            <Row className="analytics-table">
                <Col xs={12} sm={4}>
                    <div className="m-auto">
                        <h3 className="light">Videos Generated</h3>
                        <h2>
                            <CountUp
                                end={analytics.videosGenerated}
                                duration={2}
                            />
                        </h2>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div>
                        <h4>Total Views</h4>

                        <h3>
                            <CountUp end={analytics.totalViews} duration={2} />
                        </h3>
                    </div>
                    <div>
                        <h4>Total Link Clicks</h4>
                        <h3>
                            <CountUp
                                end={analytics.totalLinkClicks}
                                duration={2}
                            />
                        </h3>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div>
                        <h4>Viewed Rate</h4>
                        <h3>
                            {parseFloat(
                                (analytics.videosViewed /
                                    analytics.videosGenerated) *
                                    100
                            ).toFixed(2)}
                            %
                        </h3>
                    </div>
                    <div>
                        <h4>Engagement Rate</h4>
                        <h3>
                            {parseFloat(
                                (analytics.videosWithLinkClicked /
                                    analytics.videosGenerated) *
                                    100
                            ).toFixed(2)}
                            %
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12}>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={analytics.dailyAnalytics}>
                            <Line
                                name="Videos Generated"
                                dataKey="videosGenerated"
                                stroke="#ffd700"
                                strokeWidth={2}
                                dot={false}
                            />
                            <Line
                                name="Page Views"
                                dataKey="views"
                                stroke="#00c4ff"
                                strokeWidth={2}
                                dot={false}
                            />
                            <Line
                                name="External Links"
                                dataKey="links"
                                stroke="#2b005b"
                                strokeWidth={2}
                                dot={false}
                            />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="date" />
                            {windowSize.width > 576 ? (
                                <YAxis width={40} />
                            ) : (
                                <YAxis width={5} tick={false} />
                            )}
                            <Tooltip />
                            <Legend />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <h2 className="mb-4">
                        Where your videos are driving traffic
                    </h2>
                    <ResponsiveContainer width="100%" height={600}>
                        <BarChart data={analytics.linkClicksByUrl}>
                            <XAxis dataKey="link" height={5} tick={false} />
                            {windowSize.width > 576 ? (
                                <YAxis dataKey="clicks" width={40} />
                            ) : (
                                <YAxis
                                    dataKey="clicks"
                                    width={5}
                                    tick={false}
                                />
                            )}
                            <Tooltip />
                            <Bar dataKey="clicks">
                                {analytics.linkClicksByUrl.map((key, index) => {
                                    return (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={colors[index]}
                                        />
                                    );
                                })}
                            </Bar>
                            <Legend
                                align="left"
                                wrapperStyle={{
                                    paddingLeft: "20px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                }}
                                payload={analytics.linkClicksByUrl.map(
                                    (entry, index) => {
                                        return {
                                            value: entry["link"],
                                            id: entry["link"],
                                            type: "square",
                                            color: colors[index],
                                        };
                                    }
                                )}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </div>
    );
}

export default Analytics;

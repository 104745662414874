export const ANALYTICS_FETCH_SUCCEEDED = "ANALYTICS_FETCH_SUCCEEDED";
export const ANALYTICS_FETCH_REQUESTED = "ANALYTICS_FETCH_REQUESTED";

export function analyticsFetchSucceeded(analytics) {
    return {
        type: ANALYTICS_FETCH_SUCCEEDED,
        analytics,
    };
}

export function analyticsFetchRequested(queryString) {
    return {
        type: ANALYTICS_FETCH_REQUESTED,
        queryString,
    };
}

import { call, put, takeLatest } from "redux-saga/effects";

import { api, transformApiException } from "../api";
import { addAlert, clearAlerts } from "../actions/alerts";
import {
    loginSucceeded,
    logoutSucceeded,
    CHECK_LOGGED_IN,
    LOG_IN,
    RESET_PASSWORD,
    RESET_NEW_PASSWORD,
    LOG_OUT,
} from "../actions/auth";

function apiCheckLoggedIn() {
    return api.get('/api/auth/me', null);
}

function* checkLoggedIn(action) {
    const user = yield call(apiCheckLoggedIn);
    yield put(loginSucceeded(user));
}

function apiLogIn(credentials) {
    return api.post('/api/auth/login', credentials);
}

function* logIn(action) {
    try {
        const user = yield call(apiLogIn, action.credentials);
        yield put(clearAlerts());
        yield put(loginSucceeded(user));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiResetPassword(username) {
    return api.post('/api/auth/request-reset', {username});
}

function* resetPassword(action) {
    try {
        const user = yield call(apiResetPassword, action.username);
        yield put(clearAlerts());
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiResetNewPassword(tokenAndPassword) {
    return api.post('/api/auth/reset-password', tokenAndPassword);
}

function* resetNewPassword(action) {
    try {
        const user = yield call(apiResetNewPassword, action.tokenAndPassword);
        yield put(clearAlerts());
        yield put(loginSucceeded(user));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiLogOut() {
    return api.get('/api/auth/logout', null);
}

function* logOut(action) {
    yield call(apiLogOut);
    yield put(logoutSucceeded());
}

function* authSaga() {
    yield takeLatest(CHECK_LOGGED_IN, checkLoggedIn);
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(RESET_PASSWORD, resetPassword);
    yield takeLatest(RESET_NEW_PASSWORD, resetNewPassword);
    yield takeLatest(LOG_OUT, logOut);
}

export default authSaga;

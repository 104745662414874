import React, { Component } from 'react';
import {
    Button,
    Table,
} from 'reactstrap';

import UserForm from '../UserForm';

import {
    usersFetchRequested,
    userCreate,
    userUpdate,
    userDelete
} from '../../actions/users';

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: false,
            editing: false,
            editingObject: null,
        };

        this.startCreating = this.startCreating.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.stopCreatingOrEditing = this.stopCreatingOrEditing.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    emptyObject() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(usersFetchRequested());
    }

    startCreating() {
        this.setState({
            creating: true,
            editingObject: this.emptyObject(),
        });
    }

    startEditing(idx) {
        return function () {
            this.setState({
                editing: true,
                editingObject: {
                    id: this.props.users[idx].id,
                    firstName: this.props.users[idx].firstName,
                    lastName: this.props.users[idx].lastName,
                    email: this.props.users[idx].email,
                    password: '',
                    organizationId: this.props.users[idx].organization ? this.props.users[idx].organization.id : '',
                },
            });
        }.bind(this);
    }

    stopCreatingOrEditing() {
        this.setState({
            creating: false,
            editing: false,
            editingObject: this.emptyObject(),
        });
    }

    handleDelete(idx) {
        return function () {
            this.props.dispatch(userDelete(this.props.users[idx]));
        }.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let obj = {...this.state.editingObject}
        obj[name] = value;

        this.setState({
            editingObject: obj,
        });
    }

    handleSubmit() {
        if (this.state.creating) {
            this.props.dispatch(userCreate(this.state.editingObject));
            this.setState({
                creating: false,
                editingObject: null,
            });
        }

        if (this.state.editing) {
            this.props.dispatch(userUpdate(this.state.editingObject));
            this.setState({
                editing: false,
                editingObject: null,
            });
        }
    }

    render() {
        let content = '';
        let buttonText = this.state.creating ? 'Create' : (this.state.editing ? 'Update' : '');

        if (this.state.creating || this.state.editing) {
            content = (
                <UserForm handleSubmit={this.handleSubmit} handleChange={this.handleChange} editingObject={this.state.editingObject} organizations={this.props.organizations} buttonText={buttonText} />
            );
        } else {
            content = (
                <Table>
                    <thead>
                    <tr>
                        <th>Email</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.users.map((user, idx) => (
                        <tr key={idx}>
                            <td>{user.email}</td>
                            <td><Button onClick={this.startEditing(idx)}>Edit</Button><Button color="danger" onClick={this.handleDelete(idx)}>Delete</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )
        }

        let backButton = null;
        let headingButton = null;

        if (this.state.creating || this.state.editing) {
            backButton = <Button color="secondary" onClick={this.stopCreatingOrEditing}>Back</Button>
        } else {
            headingButton = <Button color="secondary" onClick={this.startCreating}>New</Button>
        }

        return (
            <div>
                <h1>Users {headingButton}</h1>
                {backButton}
                {content}
            </div>
        );
    }
}

export default Users;

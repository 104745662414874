export const TODAYS_VISITORS_FETCH_SUCCEEDED = 'TODAYS_VISITORS_FETCH_SUCCEEDED';
export const VISITOR_CREATE_SUCCEEDED = 'VISITOR_CREATE_SUCCEEDED';
export const VISITOR_UPDATE_SUCCEEDED = 'VISITOR_UPDATE_SUCCEEDED';
export const VISITOR_DELETE_SUCCEEDED = 'VISITOR_DELETE_SUCCEEDED';
export const VISITOR_ADD_IMAGE_SUCCEEDED = 'VISITOR_ADD_IMAGE_SUCCEEDED';
export const VISITOR_CREATE_VIDEO = 'VISITOR_CREATE_VIDEO';
export const VISITOR_CREATE_VIDEO_FAILED = 'VISITOR_CREATE_VIDEO_FAILED';
export const VISITOR_CREATE_VIDEO_SUCCEEDED = 'VISITOR_CREATE_VIDEO_SUCCEEDED';
export const VISITORS_FETCH_SUCCEEDED = 'VISITORS_FETCH_SUCCEEDED';
export const VISITORS_FETCH_REQUESTED = 'VISITORS_FETCH_REQUESTED';
export const TODAYS_VISITORS_FETCH_REQUESTED = 'TODAYS_VISITORS_FETCH_REQUESTED';
export const VISITOR_CREATE = 'VISITOR_CREATE';
export const VISITOR_UPDATE = 'VISITOR_UPDATE';
export const VISITOR_DELETE = 'VISITOR_DELETE';
export const VISITOR_ADD_IMAGE = 'VISITOR_ADD_IMAGE';
export const VISITOR_REMOVE_IMAGE = 'VISITOR_REMOVE_IMAGE';

export function todaysVisitorsFetchSucceeded(todaysVisitors) {
    return {
        type: TODAYS_VISITORS_FETCH_SUCCEEDED,
        todaysVisitors,
    }
}

export function todaysVisitorsFetchRequested() {
    return {
        type: TODAYS_VISITORS_FETCH_REQUESTED,
    }
}

export function visitorCreate(visitor) {
    return {
        type: VISITOR_CREATE,
        visitor,
    }
}

export function visitorCreateSucceeded(visitor) {
    return {
        type: VISITOR_CREATE_SUCCEEDED,
        visitor,
    }
}

export function visitorUpdate(visitor) {
    return {
        type: VISITOR_UPDATE,
        visitor,
    }
}

export function visitorUpdateSucceeded(visitor) {
    return {
        type: VISITOR_UPDATE_SUCCEEDED,
        visitor,
    }
}

export function visitorDelete(visitor) {
    return {
        type: VISITOR_DELETE,
        visitor,
    }
}

export function visitorDeleteSucceeded(visitor) {
    return {
        type: VISITOR_DELETE_SUCCEEDED,
        visitor,
    }
}

export function visitorAddImage(visitorId, imageNumber, imageFile, progressUpdate) {
    return {
        type: VISITOR_ADD_IMAGE,
        visitorId,
        imageNumber,
        imageFile,
        progressUpdate,
    }
}
export function visitorAddImageSucceeded(visitor) {
    return {
        type: VISITOR_ADD_IMAGE_SUCCEEDED,
        visitor,
    }
}
export function visitorRemoveImage(visitorId, imageNumber) {
    return {
        type: VISITOR_REMOVE_IMAGE,
        visitorId,
        imageNumber,
    }
}

export function visitorCreateVideo(visitorId) {
    return {
        type: VISITOR_CREATE_VIDEO,
        visitorId
    }
}

export function visitorCreateVideoFailed(visitorId) {
    return {
        type: VISITOR_CREATE_VIDEO_FAILED,
        visitorId
    }
}

export function visitorCreateVideoSucceeded(visitor) {
    return {
        type: VISITOR_CREATE_VIDEO_SUCCEEDED,
        visitor,
    }
}

export function visitorsFetchRequested() {
    return {
        type: VISITORS_FETCH_REQUESTED,
    }
}

export function visitorsFetchSucceeded(visitors) {
    return {
        type: VISITORS_FETCH_SUCCEEDED,
        visitors,
    }
}


export const ADD_NOTICE = 'ADD_NOTICE';
export const DISMISS_NOTICE = 'DISMISS_NOTICE';

export function addNotice() {
    return {
        type: ADD_NOTICE,
    }
}

export function dismissNotice() {
    return {
        type: DISMISS_NOTICE,
    }
}
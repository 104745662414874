import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Icon from '../Icons';

function Image(props) {
  const [updatedAt, setUpdatedAt] = useState('');
  const [progress, setProgress] = useState(0);

  const progressUpdate = useCallback(percent => {
    setProgress(percent);

    if (percent === 100) {
      setUpdatedAt(Date.now());
    }
  })

  const onDrop = useCallback(accepted => {
    if (accepted.length > 0) {
      props.handler(props.fileNumber, accepted[0], progressUpdate);
    }
  });

  const {getRootProps, getInputProps} = useDropzone({
    accept: "image/jpeg",
    multiple: false,
    onDrop
  });

  return (
    <li className="dropzone-item">
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {props.imageSrc &&
          <img
            className="thumbnail"
            src={props.imageSrc ? props.imageSrc + (updatedAt ? ('?' + updatedAt) : '') : ''}
            alt=""
          />
          }
          { (progress !== 0 && progress !== 100) &&
            <div className="progress-bar-container">
              <div className="progress-bar" style={{width: progress + '%'}}></div>
            </div>
          }
          {!props.imageSrc &&
          <Icon name="image" className="image" height={32} width={32} />
          }
        </div>
      <label>{props.title}</label>
    </li>
  );
}

export default Image;

import { connect } from 'react-redux';
import VideoTemplates from './VideoTemplates';

const mapStateToProps = state => {
    return {
        videoTemplates: state.videoTemplates
    }
};

const VideoTemplatesContainer = connect(
    mapStateToProps
)(VideoTemplates);

export default VideoTemplatesContainer

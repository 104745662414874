import React, {Component} from 'react';
import {videoTemplatesFetchRequested} from "../../actions/videoTemplates";

class VideoTemplates extends Component {
    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(videoTemplatesFetchRequested());
    }

    render() {
        return (
            <div>
                <div className="sample-image">
                    <h1>Campaigns</h1>
                    <img src="/img/campaigns.png"></img>
                </div>
                <div className="coming-soon">
                    <header className="modal-header">
                        <h2>Coming Soon</h2>
                    </header>
                    <div className="modal-body">
                        <p>We're working on a way for you to run multiple VizVid campaigns at the same time. Our current
                            forecast for release of this feature is late June 2019.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoTemplates;

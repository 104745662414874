import axios from 'axios';

class API {
    get(url) {
        return axios.get(VIZVID_BASE_URL + url, {
            withCredentials: true
        }).then(response => {
            return response.data;
        });
    }

    post(url, body, options = {}) {
        return axios.post(VIZVID_BASE_URL + url, body, {
            ...options,
            withCredentials: true
        }).then(response => {
            return response.data;
        });
    }

    delete(url) {
        return axios.delete(VIZVID_BASE_URL + url, {
            withCredentials: true
        }).then(response => {
            return response.data;
        });
    }
}

export let api = new API();

export function transformApiException(e) {
    if (e.response.status === 500) {
        return 'There was a server error.';
    } else {
        if (e.response.data.error) {
            return [e.response.data.error];
        }

        return e.response.data.errors;
    }
}

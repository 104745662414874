import React, {Component} from 'react';
import {
    FormGroup,
    InputGroup,
    Input,
    InputGroupText,
    Button
} from 'reactstrap';
import Icon from '../Icons';

class VisitorSearch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FormGroup>
                <InputGroup className="search-component">
                    <InputGroupText>
                        <Icon name="search" className="search" height={24} width={24} ariaHidden={true} tabIndex={0}/>
                    </InputGroupText>
                    <Input value={this.props.keyword} placeholder={"Type here to search for visitors by name or email"}
                           onChange={this.props.handleKeywordChange} disabled={this.props.disable}/>
                    {this.props.keyword &&
                    <Button aria-label="Clear search" onClick={this.props.handleClearKeyword}>
                        Clear
                    </Button>
                    }
                </InputGroup>
            </FormGroup>
        );
    }
}

export default VisitorSearch;

import { call, put, takeLatest } from "redux-saga/effects";

import { api, transformApiException } from "../api";
import { addAlert } from "../actions/alerts";
import {
    analyticsFetchSucceeded,
    ANALYTICS_FETCH_REQUESTED,
} from "../actions/analytics";

function apiFetchAnalytics(queryString) {
    return api.get("/api/analytics?" + queryString, null);
}

function* fetchAnalytics(action) {
    try {
        const analytics = yield call(apiFetchAnalytics, action.queryString);
        yield put(analyticsFetchSucceeded(analytics));
    } catch (e) {
        yield put(addAlert("danger", transformApiException(e)));
    }
}

function* analyticsSaga() {
    yield takeLatest(ANALYTICS_FETCH_REQUESTED, fetchAnalytics);
}

export default analyticsSaga;

import { call, put, takeLatest } from "redux-saga/effects";

import { api, transformApiException } from "../api";
import { addAlert } from "../actions/alerts";
import {
    usersFetchSucceeded,
    userCreateSucceeded,
    userUpdateSucceeded,
    userDeleteSucceeded,
    USERS_FETCH_REQUESTED,
    USER_CREATE,
    USER_UPDATE,
    USER_DELETE,
} from "../actions/users";


function apiFetchUsers() {
    return api.get('/api/users', null);
}

function* fetchUsers(action) {
    try {
        const users = yield call(apiFetchUsers);
        yield put(usersFetchSucceeded(users));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiCreateUser(user) {
    return api.post('/api/users', user);
}

function* createUser(action) {
    try {
        const user = yield call(apiCreateUser, action.user);
        yield put(userCreateSucceeded(user));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiUpdateUser(user) {
    return api.post('/api/users/' + user.id, user);
}

function* updateUser(action) {
    try {
        const user = yield call(apiUpdateUser, action.user);
        yield put(userUpdateSucceeded(user));
    } catch (e) {
        yield put(addAlert('danger', transformApiException(e)));
    }
}

function apiDeleteUser(user) {
    return api.delete('/api/users/' + user.id);
}

function* deleteUser(action) {
    try {
        yield call(apiDeleteUser, action.user);
        yield put(userDeleteSucceeded(action.user));
    } catch (e) {
        yield put(addAlert('danger', e.message));
    }
}

function* usersSaga() {
    yield takeLatest(USERS_FETCH_REQUESTED, fetchUsers);
    yield takeLatest(USER_CREATE, createUser);
    yield takeLatest(USER_UPDATE, updateUser);
    yield takeLatest(USER_DELETE, deleteUser);
}

export default usersSaga;

import {
  USERS_FETCH_SUCCEEDED,
  USER_CREATE_SUCCEEDED,
  USER_UPDATE_SUCCEEDED,
  USER_DELETE_SUCCEEDED
} from '../actions/users';

export const users = (state = [], action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCEEDED:
      return action.users;
    case USER_CREATE_SUCCEEDED:
      return [
        ...state,
        action.user
      ];
    case USER_UPDATE_SUCCEEDED:
      return state.map((user, idx) => {
        if (user.id !== action.user.id) {
          return user;
        }
        return action.user;
      });
    case USER_DELETE_SUCCEEDED:
      const userIdx = state.findIndex(user => user.id === action.user.id);

      return [
        ...state.slice(0, userIdx),
        ...state.slice(userIdx + 1)
      ];
    default:
      return state
  }
};

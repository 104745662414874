import React, { Component } from "react";
import { Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import Visitor from "../Visitor";
import VisitorForm from "../VisitorForm";
import VisitorSearch from "../VisitorSearch";

import { videoTemplatesFetchRequested } from "../../actions/videoTemplates";
import { visitorCreate, visitorsFetchRequested } from "../../actions/visitors";

export class Visitors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: false,
            page: 1,
            visitorsPerPage: 20,
            keyword: ""
        };

        this.startCreating = this.startCreating.bind(this);
        this.stopCreating = this.stopCreating.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.paginatedVisitors = this.paginatedVisitors.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleClearKeyword = this.handleClearKeyword.bind(this);
        this.filterMatches = this.filterMatches.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(visitorsFetchRequested());
        dispatch(videoTemplatesFetchRequested());
    }

    startCreating() {
        this.setState({
            creating: true
        });
    }

    stopCreating() {
        this.setState({
            creating: false
        });
    }

    handleSubmit(visitor) {
        this.props.dispatch(visitorCreate(visitor));
        this.setState({
            creating: false
        });
    }

    paginatedVisitors() {
        const page = this.state.page;
        const visitorsPerPage = this.state.visitorsPerPage;

        return this.filterMatches().slice(
            (page - 1) * visitorsPerPage,
            page * visitorsPerPage
        );
    }

    previousPage() {
        if (this.state.page > 1) {
            this.setState({
                page: this.state.page - 1
            });
        }
    }

    nextPage() {
        if (
            this.state.page <
            Math.ceil(this.props.visitors.length / this.state.visitorsPerPage)
        ) {
            this.setState({
                page: this.state.page + 1
            });
        }
    }

    handleKeywordChange(e) {
        this.setState({
            keyword: e.target.value
        });
    }

    handleClearKeyword() {
        this.setState({
            keyword: ""
        });
    }

    filterMatches() {
        const keyword = this.state.keyword.toLowerCase();

        if (!keyword) {
            return this.props.visitors;
        }

        let matches = [];

        this.props.visitors.map(visitor => {
            if (
                visitor.firstName.toLowerCase().includes(keyword) ||
                visitor.lastName.toLowerCase().includes(keyword) ||
                visitor.email.toLowerCase().includes(keyword)
            ) {
                matches.push(visitor);
            }
        });

        return matches;
    }

    render() {
        let newForm = null;

        if (this.state.creating) {
            newForm = (
                <VisitorForm
                    videoTemplates={this.props.videoTemplates}
                    handleSubmit={this.handleSubmit}
                    buttonText="Create"
                />
            );
        }

        return (
            <div>
                <div className="page-header">
                    <h1>
                        {this.state.creating ? "Adding Visitor" : "Visitors"}
                    </h1>
                    <p>
                        {this.state.creating
                            ? "Manually add a contact using the form below and send them a VizVid."
                            : "Here are all of the visitors to all of your VizVid campaigns."}
                    </p>
                </div>
                <div className="search-container">
                    <VisitorSearch
                        keyword={this.state.keyword}
                        handleKeywordChange={this.handleKeywordChange}
                        handleClearKeyword={this.handleClearKeyword}
                        disable={this.state.creating}
                    />
                    <Button
                        onClick={
                            this.state.creating
                                ? this.stopCreating
                                : this.startCreating
                        }
                        aria-live={"polite"}
                        className="mb-2 mb-xl-0"
                        aria-label={
                            this.state.creating ? "Cancel" : "Add visitor"
                        }
                    >
                        {this.state.creating ? "Cancel" : "Add visitor"}
                    </Button>
                </div>
                {newForm}
                {!this.state.creating && (
                    <div className="module-wrapper">
                        <ul
                            className="visitors-list"
                            role="list"
                            tabIndex={0}
                            aria-label="Visitors list"
                        >
                            {this.paginatedVisitors().map(visitor => (
                                <Visitor
                                    key={visitor.id}
                                    visitor={visitor}
                                    videoTemplates={this.props.videoTemplates}
                                    dispatch={this.props.dispatch}
                                />
                            ))}
                        </ul>
                        <Pagination>
                            <PaginationItem>
                                <PaginationLink
                                    previous
                                    onClick={this.previousPage}
                                />
                            </PaginationItem>
                            <span className="page-number">
                                Page: {this.state.page}
                            </span>
                            <PaginationItem>
                                <PaginationLink next onClick={this.nextPage} />
                            </PaginationItem>
                        </Pagination>
                    </div>
                )}
            </div>
        );
    }
}

import { connect } from 'react-redux'
import App from './App'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
};

const AppContainer = connect(
    mapStateToProps
)(App);
export default AppContainer

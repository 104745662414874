import React, { Component } from 'react';
import { format, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';
import {
    Row,
    Col,
    Button,
    Form,
    FormFeedback,
    Input,
    Label,
} from 'reactstrap';
import DateAndTime from '../DateAndTime'

class VisitorForm extends Component {
    constructor(props) {
        super(props);

        if (this.props.visitor) {
            this.state = {
                id: this.props.visitor.id,
                firstName: this.props.visitor.firstName,
                lastName: this.props.visitor.lastName,
                email: this.props.visitor.email,
                videoTemplateId: this.props.visitor.videoTemplate.id,
                visitDateAndTime: this.props.visitor.visitDateAndTime,
                otherInfo: this.props.visitor.otherInfo
            }
        } else {
            let visitDateAndTime = new Date();
            visitDateAndTime = setHours(visitDateAndTime, 12);
            visitDateAndTime = setMinutes(visitDateAndTime, 0);
            visitDateAndTime = setSeconds(visitDateAndTime, 0);

            this.state = {
                id: null,
                firstName: '',
                lastName: '',
                email: '',
                videoTemplateId: '',
                visitDateAndTime: visitDateAndTime,
                otherInfo: {}
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleOtherInfoChange = this.handleOtherInfoChange.bind(this);
        this.handleVisitDateAndTimeChange = this.handleVisitDateAndTimeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleOtherInfoChange(e) {
        const otherInfo = {...this.state.otherInfo};

        otherInfo[e.target.name] = e.target.value;

        this.setState({
            otherInfo: otherInfo
        });
    }

    handleVisitDateAndTimeChange(dateAndTime) {
        this.setState({
            visitDateAndTime: dateAndTime,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit(this.state);
    }

    render() {
        return (
            <div className="module-wrapper p-xl-4 p-2 add-visitor">
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <Label for="firstName">First Name</Label>
                                <Input
                                    name="firstName"
                                    id="firstName"
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </div>
                            <div className="form-group">
                                <Label for="lastName">Last Name</Label>
                                <Input
                                    name="lastName"
                                    id="lastName"
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </div>
                            <div className="form-group">
                                <Label for="email">Email</Label>
                                <Input
                                    name="email"
                                    id="email"
                                    required
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </div>
                            <div className="form-group">
                                <Label for="major">Major</Label>
                                <Input
                                    name="major"
                                    id="major"
                                    required
                                    value={this.state.otherInfo && this.state.otherInfo.major ? this.state.otherInfo.major : ''}
                                    onChange={this.handleOtherInfoChange}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DateAndTime
                                value={this.state.visitDateAndTime}
                                onChange={this.handleVisitDateAndTimeChange}
                            />
                            <div className="form-group select-group">
                                <Label for="videoTemplateId">Video Template</Label>
                                <Input
                                    type="select"
                                    name="videoTemplateId"
                                    value={this.state.videoTemplateId}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select a campaign template</option>
                                    {this.props.videoTemplates.map((videoTemplate, idx) => (
                                        <option key={idx} value={videoTemplate.id}>{videoTemplate.name}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="submit-wrapper">
                            <Button onClick={this.props.handleDelete} className="me-xl-2">Delete</Button>
                            <Button color="primary" type="submit" className="mb-2 mb-xl-0">
                                {this.props.buttonText}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default VisitorForm;

import React, {Component} from "react";

class Icon extends Component {
    constructor(props) {
        super(props)
    }

    renderIcon() {
        switch (this.props.name) {
            case 'search' :
                return (
                    <path
                        d="M23.954 21.125l5.774 5.774-2.829 2.829-5.774-5.774c-4.686 3.34-11.234 2.907-15.439-1.297C1 17.97 1 10.373 5.686 5.687 10.373 1 17.971 1 22.656 5.686c4.205 4.204 4.638 10.752 1.298 15.438zm-4.126-1.297A8 8 0 1 0 8.515 8.515a8 8 0 0 0 11.313 11.313z"
                        fill={this.props.color} fillRule="nonzero"/>
                );
            case 'image' :
                return (
                    <path fill={this.props.color} fillRule="nonzero"
                          d="M2.13333333 4C.96378663 4 0 4.95669887 0 6.11764457V25.8823554C0 27.0432835.96378663 28 2.13333333 28H29.8666667C31.0362133 28 32 27.0432835 32 25.8823554V6.11764457C32 4.95669535 31.0362133 4 29.8666667 4H2.13333333zm0 1.41176304H29.8666667c.4062364 0 .7111111.30263259.7111111.70588153V22.9926529l-6.2333334-6.7720508c-.1436551-.1515881-.3574968-.2348115-.5666666-.220588-.1796516.0098824-.3543502.0906352-.4777778.220588l-5.0888889 5.3602878-7.3666667-8.1727774c-.1572977-.1777975-.4089386-.2682385-.6444444-.2316174-.1566756.0252353-.304032.1042869-.41111111.220588l-8.3666667 8.9227589V6.11764457c0-.40324894.30487466-.70588153.71111114-.70588153zM18.4888889 8.94120595c-1.5625209 0-2.8444445 1.27249615-2.8444445 2.82352605 0 1.55103 1.2819236 2.8235261 2.8444445 2.8235261 1.5625209 0 2.8444444-1.2724961 2.8444444-2.8235261 0-1.5510299-1.2819235-2.82352605-2.8444444-2.82352605zm0 1.41176305c.7938951 0 1.4222222.6237063 1.4222222 1.411763 0 .7880568-.6283271 1.4117631-1.4222222 1.4117631s-1.4222222-.6237063-1.4222222-1.4117631c0-.7880567.6283271-1.411763 1.4222222-1.411763zM10.3 14.9301695L20.8111111 26.588237H2.13333333c-.40623648 0-.71111114-.3026467-.71111114-.7058816v-1.4889865L10.3 14.9301695zm13.5111111 2.8124896l6.7666667 7.3455796v.7941167c0 .4032349-.3048747.7058816-.7111111.7058816h-7.1444445l-3.5555555-3.9485248 4.6444444-4.8970531z"/>

                );
        }
    }

    render() {
        const name = this.props.name;

        if (name !== '') {
            return (
                <svg
                    className={"icon icon-" + this.props.className}
                    viewBox="0 0 32 32"
                    height={this.props.height}
                    width={this.props.width}
                    xmlns="http://www.w3.org/2000/svg"
                    title={this.props.title}
                    description={this.props.description}
                    aria-labelledby={this.props.name + "Title " + this.props.name + "Desc"}
                    aria-hidden={this.props.ariaHidden}
                    tabIndex={this.props.tabIndex}
                >
                    <title id={this.props.name + "Title"}>{this.props.title}</title>
                    <desc id={this.props.name + "Desc"}>{this.props.description}</desc>
                    {this.renderIcon()}
                </svg>
            )
        } else {
            return ('no icon')
        }
    }
}

export default Icon;
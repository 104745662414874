import React, { Component } from 'react';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

class UserForm extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.props.handleChange(e);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit();
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                        name="firstName"
                        id="firstName"
                        required
                        value={this.props.editingObject.firstName}
                        onChange={this.handleChange}
                    />
                    <FormFeedback>This field is required</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                        name="lastName"
                        id="lastName"
                        required
                        value={this.props.editingObject.lastName}
                        onChange={this.handleChange}
                    />
                    <FormFeedback>This field is required</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        name="email"
                        id="email"
                        required
                        value={this.props.editingObject.email}
                        onChange={this.handleChange}
                    />
                    <FormFeedback>This field is required</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        value={this.props.editingObject.password}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <Button
                    color="primary"
                    type="submit"
                >
                    {this.props.buttonText}
                </Button>
            </Form>
        );
    }
}

export default UserForm;

import { LOG_IN_SUCCEEDED, LOG_OUT_SUCCEEDED } from "../actions/auth";


export const auth = (state = false, action) => {
  switch (action.type) {
    case LOG_IN_SUCCEEDED:
      return action.currentUser;
    case LOG_OUT_SUCCEEDED:
      return false;
    default:
      return state
  }
};

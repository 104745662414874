import { connect } from 'react-redux';
import Alerts from './Alerts';

const mapStateToProps = state => {
    return {
        alerts: state.alerts
    }
};

const AlertsContainer = connect(
    mapStateToProps
)(Alerts);
export default AlertsContainer

export const LOG_IN_SUCCEEDED = 'LOG_IN_SUCCEEDED';
export const LOG_OUT_SUCCEEDED = 'LOG_OUT_SUCCEEDED';
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN';
export const LOG_IN = 'LOG_IN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_NEW_PASSWORD = 'RESET_NEW_PASSWORD';
export const LOG_OUT = 'LOG_OUT';

export function login(credentials) {
    return {
        type: LOG_IN,
        credentials,
    }
}

export function loginSucceeded(currentUser) {
    return {
        type: LOG_IN_SUCCEEDED,
        currentUser,
    }
}

export function logout() {
    return {
        type: LOG_OUT,
    }
}

export function logoutSucceeded() {
    return {
        type: LOG_OUT_SUCCEEDED,
    }
}

export function resetPassword(username) {
    return {
        type: RESET_PASSWORD,
        username,
    }
}

export function resetNewPassword(token, password) {
    return {
        type: RESET_NEW_PASSWORD,
        tokenAndPassword: {
            token,
            password,
        },
    }
}

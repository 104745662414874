import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { reducers } from "./reducers";

import createSagaMiddleware from "redux-saga";
import analyticsSaga from "./sagas/analytics";
import authSaga from "./sagas/auth";
import usersSaga from "./sagas/users";
import visitorsSaga from "./sagas/visitors";
import videoTemplatesSaga from "./sagas/videoTemplates";

import AppContainer from "./components/App";

import "./assets/theme/theme.scss";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(visitorsSaga);
sagaMiddleware.run(analyticsSaga);
sagaMiddleware.run(videoTemplatesSaga);

store.dispatch({ type: "CHECK_LOGGED_IN" });

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <AppContainer />
    </Provider>
);

import { del } from 'object-path-immutable';

import {
  TODAYS_VISITORS_FETCH_SUCCEEDED,
  VISITOR_CREATE_SUCCEEDED,
  VISITOR_UPDATE_SUCCEEDED,
  VISITOR_DELETE_SUCCEEDED,
  VISITOR_ADD_IMAGE_SUCCEEDED,
  VISITOR_REMOVE_IMAGE,
  VISITOR_CREATE_VIDEO,
  VISITOR_CREATE_VIDEO_FAILED,
  VISITOR_CREATE_VIDEO_SUCCEEDED,
  VISITORS_FETCH_SUCCEEDED} from "../actions/visitors";

export const todaysVisitors = (state = [], action) => {
  switch (action.type) {
    case TODAYS_VISITORS_FETCH_SUCCEEDED:
      return action.todaysVisitors;
    case VISITOR_CREATE_SUCCEEDED:
      if (action.visitor.visitDateAndTime.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        return [
          ...state,
          action.visitor
        ]
      }

      return state;
    case VISITOR_UPDATE_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    case VISITOR_DELETE_SUCCEEDED:
      const visitorIdx = state.findIndex(visitor => visitor.id === action.visitor.id);

      if (visitorIdx) {
        return [
          ...state.slice(0, visitorIdx),
          ...state.slice(visitorIdx + 1)
        ];
      }

      return state;
    case VISITOR_ADD_IMAGE_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    case VISITOR_REMOVE_IMAGE:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        return del(visitor, 'images.image' + action.imageNumber);
      });
    case VISITOR_CREATE_VIDEO:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        visitor.generating = true;
        return visitor;
      });
    case VISITOR_CREATE_VIDEO_FAILED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        visitor.generating = false;
        return visitor;
      });
    case VISITOR_CREATE_VIDEO_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    default:
      return state
  }
}

export const visitors = (state = [], action) => {
  switch (action.type) {
    case VISITORS_FETCH_SUCCEEDED:
      return action.visitors;
    case VISITOR_CREATE_SUCCEEDED:
      return [
        ...state,
        action.visitor
      ]
    case VISITOR_UPDATE_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    case VISITOR_DELETE_SUCCEEDED:
      const visitorIdx = state.findIndex(visitor => visitor.id === action.visitor.id);

      return [
        ...state.slice(0, visitorIdx),
        ...state.slice(visitorIdx + 1)
      ];
    case VISITOR_ADD_IMAGE_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    case VISITOR_REMOVE_IMAGE:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        return del(visitor, 'images.image' + action.imageNumber);
      });
    case VISITOR_CREATE_VIDEO:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        visitor.generating = true;
        return visitor;
      });
    case VISITOR_CREATE_VIDEO_FAILED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitorId) {
          return visitor;
        }

        visitor.generating = false;
        return visitor;
      });
    case VISITOR_CREATE_VIDEO_SUCCEEDED:
      return state.map((visitor, idx) => {
        if (visitor.id !== action.visitor.id) {
          return visitor;
        }
        return action.visitor;
      });
    default:
      return state
  }
};

import { connect } from 'react-redux'
import Login from './Login'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
};

export const LoginContainer = connect(
    mapStateToProps
)(Login);

import { VIDEO_TEMPLATES_FETCH_SUCCEEDED } from "../actions/videoTemplates";

export const videoTemplates = (state = [], action) => {
  switch (action.type) {
    case VIDEO_TEMPLATES_FETCH_SUCCEEDED:
      return action.videoTemplates;
    default:
      return state
  }
};

import { combineReducers } from "redux";
import { alerts } from "./alerts";
import { auth } from "./auth";
import { todaysVisitors, visitors } from "./visitors";
import { users } from "./users";
import { videoTemplates } from "./videoTemplates";
import { notices } from "./notices";
import { analytics } from "./analytics";

export const reducers = combineReducers({
    alerts,
    currentUser: auth,
    visitors,
    todaysVisitors,
    users,
    videoTemplates,
    notices,
    analytics,
});

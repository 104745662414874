import { ANALYTICS_FETCH_SUCCEEDED } from "../actions/analytics";

let defaultState = {
    dailyAnalytics: [],
    linkClicksByUrl: [],
    videosGenerated: 0,
    videosViewed: 0,
    videosWithLinkClicked: 0,
    totalViews: 0,
    totalLinkClicks: 0,
};

export const analytics = (state = defaultState, action) => {
    switch (action.type) {
        case ANALYTICS_FETCH_SUCCEEDED:
            return action.analytics;
        default:
            return state;
    }
};

import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { dismissAlert } from "../../actions/alerts";

class Alerts extends Component {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss(idx) {
    const self = this;

    return function () {
      self.props.dispatch(dismissAlert(idx));
    }
  }

  render() {
    return (
      <div className="alerts-container">
        { this.props.alerts.map((notice, idx) => {
          return <Alert
              key={idx}
              color={notice.type}
              isOpen={true}
              toggle={this.handleDismiss(idx)}
          >
            {notice.messages.map((message, idx) => (<div key={idx}>{message}</div>))}
          </Alert>
        }) }
      </div>
    )
  }
}

export default Alerts;

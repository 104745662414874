import React, { Component } from 'react';

import Visitor from '../Visitor';
import {videoTemplatesFetchRequested} from "../../actions/videoTemplates";
import {todaysVisitorsFetchRequested} from "../../actions/visitors";

class TodaysVisitors extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(todaysVisitorsFetchRequested());
        dispatch(videoTemplatesFetchRequested());
    }

    render() {
        return (
            <div>
                <div className="page-header">
                    <h1>Today's Visitors</h1>
                    <p>Here are all of today's visitors to all of your VizVid campaigns.</p>
                </div>
                <div className="module-wrapper">
                    <ul className="visitors-list" role="list" tabIndex={0} aria-label="Today's visitors list">
                        {this.props.todaysVisitors.map((visitor, idx) => (
                            <Visitor
                                key={idx}
                                visitor={visitor}
                                videoTemplates={this.props.videoTemplates}
                                dispatch={this.props.dispatch}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default TodaysVisitors;

import {ADD_NOTICE, DISMISS_NOTICE} from "../actions/notices";

export const notices = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTICE:
      return [
        ...state,
        {
          type: action.msgType,
          message: action.message,
        }
      ]
    case DISMISS_NOTICE:
      return [
        ...state.slice(0, action.idx),
        ...state.slice(action.idx + 1)
      ]
    default:
      return state
  }
};

import { connect } from 'react-redux';
import TodaysVisitors from './TodaysVisitors'

const mapStateToProps = state => {
    return {
        todaysVisitors: state.todaysVisitors,
        videoTemplates: state.videoTemplates,
    }
};

const TodaysVisitorsContainer = connect(
    mapStateToProps
)(TodaysVisitors);

export default TodaysVisitorsContainer;

import React from "react";

const VizVidLogo = (props) => (
    <svg viewBox="0 0 96 64" id="vizvidLogo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-labelledby="vizvidTitle vizvidDesc" role="img" tabIndex={props.tabIndex} aria-hidden={props.ariaHidden}>
        <title id="vizvidTitle">{props.title}</title>
        <desc id="vizvidDesc">{props.description}</desc>
        <path d="M58.276 64c-3.024 0-5.798-1.536-7.43-4.12l-24.76-39.19C20.615 12.22 15.05 8.724 13.99 8.11L0 0h43.57l29.834 47.223-7.611 12.524c-1.602 2.635-4.382 4.226-7.437 4.253h-.08zM27.75 8.683a58.83 58.83 0 0 1 5.53 7.287l24.782 39.227c.03.049.082.128.219.12.14-.001.185-.074.215-.123l4.79-7.882L38.882 8.683H27.75zm48.457 33.925L49.291 0h38.993c2.793 0 5.375 1.536 6.737 4.008a7.917 7.917 0 0 1-.167 7.914L76.207 42.608zM64.951 8.683L76.067 26.28 86.76 8.683H64.951z"
              fill="#000000" fillRule="evenodd"/>
    </svg>
);

export default VizVidLogo;
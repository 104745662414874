import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { login as loginAction, resetPassword, resetNewPassword } from '../../actions/auth';
import VizVidLogo from "../Logo";

function Login(props) {
    const {resetToken} = useParams();

    const [login, setLogin] = useState(true);
    const [reset, setReset] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    function showLogin(e) {
        e.preventDefault();

        setLogin(true);
        setReset(false);
        setCheckEmail(false);
    }

    function handleLogIn(e) {
        e.preventDefault();

        props.dispatch(loginAction({
            username: email,
            password: password
        }));
    }

    function showReset(e) {
        e.preventDefault();

        setLogin(false);
        setReset(true);
        setCheckEmail(false);
    }

    function handlePasswordReset(e) {
        e.preventDefault();

        props.dispatch(resetPassword(email));

        setLogin(false);
        setReset(false);
        setCheckEmail(true);
    }

    function handleNewPassword(e) {
        e.preventDefault();

        props.dispatch(resetNewPassword(resetToken, newPassword));
    }

    if (resetToken) {
        return (
            <div className="container-fluid login-box">
                <Row>
                    <Col lg={{size: 4, offset: 4}} sm={{size: 6, offset: 2}}>
                        <div className="position-relative">
                            <div className="logo-circle">
                                <VizVidLogo title={"Welcome to VizVid"} />
                            </div>
                            <h2>Reset Password</h2>
                            <Form onSubmit={handleNewPassword} tabIndex={0} role={"form"} aria-label={"Set a new password"}>
                                <FormGroup>
                                    <Label for="new_password">New Password</Label>
                                    <Input type="password" name="newPassword" id="new_password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                </FormGroup>
                                <div className="row">
                                    <div className="col d-flex justify-content-between align-items-center">
                                        <Button type="submit" color="primary">Set New Password</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return (
            <div className="container-fluid login-box">
                <Row>
                    <Col lg={{size: 4, offset: 4}} sm={{size: 6, offset: 2}}>
                        {login &&
                        <div className="position-relative">
                            <div className="logo-circle">
                                <VizVidLogo title={"Welcome to VizVid"} />
                            </div>
                            <h2>Login</h2>
                            <Form onSubmit={handleLogIn} tabIndex={0} role={"form"} aria-label={"Login to VizVid"}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                                </FormGroup>
                                <div className="row">
                                    <div className="col d-flex justify-content-between align-items-center">
                                        <Button type="submit" color="primary">Log In</Button>
                                        <a className="btn btn-text" href="/forgot-password" onClick={showReset}>Need help?</a>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        }
                        {reset &&
                        <div className="position-relative">
                            <div className="logo-circle">
                                <VizVidLogo title={"Welcome to VizVid"} />
                            </div>
                            <h2>Reset Password</h2>
                            <Form onSubmit={handlePasswordReset}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                                </FormGroup>
                                <div className="row">
                                    <div className="col d-flex justify-content-between align-items-center">
                                        <Button type="submit" color="primary">Request Reset</Button>
                                        <a className="btn btn-text" href="/login" onClick={showLogin}>Wait, I know it!</a>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        }
                        {checkEmail &&
                        <div>
                            <h1>Check Your Email</h1>
                            <p>If your email address is associated with an account on VizVid, you'll receive an email with a link to reset your password.</p>
                        </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Login;

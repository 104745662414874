export const VIDEO_TEMPLATES_FETCH_SUCCEEDED = 'VIDEO_TEMPLATES_FETCH_SUCCEEDED';
export const VIDEO_TEMPLATES_FETCH_REQUESTED = "VIDEO_TEMPLATES_FETCH_REQUESTED";

export function videoTemplatesFetchSucceeded(videoTemplates) {
    return {
        type: VIDEO_TEMPLATES_FETCH_SUCCEEDED,
        videoTemplates,
    }
}

export function videoTemplatesFetchRequested() {
    return {
        type: VIDEO_TEMPLATES_FETCH_REQUESTED,
    }
}

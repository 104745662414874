import {ADD_ALERT, DISMISS_ALERT, CLEAR_ALERTS} from "../actions/alerts";

export const alerts = (state = [], action) => {
  switch (action.type) {
    case ADD_ALERT:
      let messages = [];

      if (typeof action.message === 'string') {
        messages.push(action.message);
      } else {
        messages = action.message;
      }

      return [
        ...state,
        {
          type: action.msgType,
          messages: messages,
        }
      ];
    case DISMISS_ALERT:
      return [
        ...state.slice(0, action.idx),
        ...state.slice(action.idx + 1)
      ];
    case CLEAR_ALERTS:
      return [];
    default:
      return state;
  }
};

import React, { Component } from 'react';
import {
    Input,
    Label,
} from 'reactstrap';
import { format, parse } from 'date-fns';

class DateAndTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: format(this.props.value, "yyyy-MM-dd"),
            time: format(this.props.value, "HH:mm"),
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleDateChange(e) {
        if (e.target.value) {
            // don't update if they put in a 5 digit year, will update this in year 9999
            if (e.target.value.split('-')[0].length > 4) {
                return;
            }

            let date = parse(e.target.value, "yyyy-MM-dd", new Date());
            date = parse(this.state.time, "HH:mm", date);
            this.props.onChange(date);

            this.setState({
                date: e.target.value,
            });
        }
    }

    handleTimeChange(e) {
        if (e.target.value) {
            let date = parse(this.state.date, "yyyy-MM-dd", new Date());
            date = parse(e.target.value, "HH:mm", date);
            this.props.onChange(date);

            this.setState({
                time: e.target.value,
            });
        }
    }

    render() {
        return (
            <div className="date-time-input-group">
                <div className="form-group date">
                    <Label for="visitDateAndTime">Visit Date</Label>
                    <Input
                        type="date"
                        value={this.state.date}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="form-group time">
                    <Label for="visitDateAndTime">Visit Time</Label>
                    <Input
                        type="time"
                        value={this.state.time}
                        onChange={this.handleTimeChange}
                    />
                </div>
            </div>
        );
    }
}

export default DateAndTime;

import React, {Component} from 'react';
import {
    Alert,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Collapse,
} from 'reactstrap';
import Spinner from 'react-spinkit';
import {format} from 'date-fns';

import VisitorForm from '../VisitorForm';
import Image from "../Image";

import {addAlert} from "../../actions/alerts";
import {visitorAddImage, visitorCreateVideo, visitorDelete, visitorUpdate} from "../../actions/visitors";

class Visitor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editVizVid: false,
            editVisitor: false,
            squareAlert: true,
            isActionsOpen: false,
            copyButton: 'Copy Link'
        };

        this.toggleVizVidEditor = this.toggleVizVidEditor.bind(this);
        this.toggleVisitorEditor = this.toggleVisitorEditor.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleCreateVideo = this.handleCreateVideo.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.isCreateDisabled = this.isCreateDisabled.bind(this);
        this.handleButtonLabel = this.handleButtonLabel.bind(this);
        this.toggleActions = this.toggleActions.bind(this);
        this.handleVizVidEditorButtonLabel = this.handleVizVidEditorButtonLabel.bind(this);
    }

    toggleActions() {
        this.setState({isActionsOpen: !this.state.isActionsOpen});
    }

    toggleVizVidEditor() {
        this.setState({
            editVizVid: !this.state.editVizVid,
            editVisitor: false
        });
    }

    toggleVisitorEditor() {
        this.setState({
            editVizVid: false,
            editVisitor: !this.state.editVisitor
        });
    }

    handleAddImage(imageNumber, imageFile, progressUpdate) {
        this.props.dispatch(visitorAddImage(this.props.visitor.id, imageNumber, imageFile, progressUpdate));
    }

    handleCreateVideo() {
        this.props.dispatch(visitorCreateVideo(this.props.visitor.id));
    }

    handleUpdate(visitor) {
        this.props.dispatch(visitorUpdate(visitor));
    }

    handleDelete() {
        this.props.dispatch(visitorDelete(this.props.visitor));
    }

    handleDismiss() {
        this.setState({ squareAlert: false });
    }

    isCreateDisabled() {
        if (this.props.visitor.generating || (Object.keys(this.props.visitor.images).length != JSON.parse(this.props.visitor.videoTemplate.images).length)) {
            return true
        } else {
            return false
        }
    }

    handleButtonLabel() {
        if (this.props.visitor.generating !== true && this.props.visitor.videoGenerated) {
            return 'Re-Create Video'
        } else if (this.props.visitor.generating === true) {
            return `Creating`
        } else {
            return 'Create Video'
        }
    }

    handleVizVidEditorButtonLabel() {
        if (this.props.visitor.videoGenerated && this.state.editVizVid) {
            return 'Close'
        } else if (this.props.visitor.videoGenerated && !this.state.editVizVid) {
            return 'Edit VizVid'
        } else {
            return 'Create VizVid'
        }
    }

    copyToClipboard(e) {
        this.urlArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        this.setState({ copyButton: (this.state.copyButton === 'Copy Link' ? 'Copied!' : 'Copy Link' )});
    };

    render() {
        const campaignName = this.props.visitor.videoTemplate.name;
        const campaignNameMod = (campaignName).replace(/\s+/g, '-').toLowerCase();
        return (
            <li tabIndex={0}>
                <div className="visitor">
                    <div className="visitor-info-wrapper">
                        <div className="visitor-info">
                            <span className="visitor-name">
                                {this.props.visitor.lastName}, {this.props.visitor.firstName}
                            </span>
                                <span className="visitor-email">
                                {this.props.visitor.email}
                            </span>
                        </div>
                    </div>
                    <div className="visit-datetime">
                        <span className="timestamp">{format(this.props.visitor.visitDateAndTime, 'M/d/y @ h:mma')}</span>
                        <label>Visit date and time</label>
                    </div>
                    <div className="visitor-actions">
                        <Button color="text" onClick={this.toggleVisitorEditor} tabIndex={0}>
                            {this.state.editVisitor ? 'Close' : 'Edit Visitor'}
                        </Button>
                        <Button color="text" onClick={this.toggleVizVidEditor} tabIndex={0}>
                            {this.handleVizVidEditorButtonLabel()}
                        </Button>
                        {this.props.visitor.generating !== true && this.props.visitor.videoGenerated ?
                            <Button color="text" onClick={this.copyToClipboard}>
                                {this.state.copyButton}
                            </Button>
                            :
                            ''
                        }
                        <textarea
                            className="visually-hidden"
                            ref={(urlArea) => this.urlArea = urlArea}
                            onChange={this.copyToClipboard}
                            value={VIZVID_BASE_URL + "/share/" + this.props.visitor.id}
                        />
                    </div>
                </div>
                <Collapse isOpen={this.state.editVizVid} className="vizvid-editor">
                    {JSON.parse(this.props.visitor.videoTemplate.images).length > 0 &&
                    <Alert color="info" isOpen={this.state.squareAlert} toggle={this.handleDismiss}>
                        <span id="image-upload-guidelines">The "{campaignName}" campaign allows you to add JPEG images to VizVids. <strong>Please upload square images under 2MB</strong>.</span>
                    </Alert>
                    }
                    {this.state.editVizVid && // don't load imgs before they're necessary
                    <ul className="dropzones">
                        {JSON.parse(this.props.visitor.videoTemplate.images).map((image, idx) => (
                            <Image
                                fileNumber={idx}
                                key={idx}
                                title={image.name}
                                handler={this.handleAddImage}
                                imageSrc={this.props.visitor.images['image' + idx] ? (S3_BASE_URL + '/' + this.props.visitor.organization.id + '/images/' + this.props.visitor.images['image' + idx]) : ''}
                            />
                        ))}
                    </ul>
                    }
                    <div className="submit-wrapper">
                        <Button disabled={this.isCreateDisabled()} color="primary"
                                onClick={this.handleCreateVideo}>
                            {this.handleButtonLabel()}
                            {this.props.visitor.generating ?
                                <span className="progress"><Spinner name="wave"/></span>
                                :
                                ''
                            }
                        </Button>
                    </div>
                </Collapse>
                <Collapse isOpen={this.state.editVisitor} className="visitor-editor">
                    <VisitorForm
                        handleSubmit={this.handleUpdate}
                        visitor={this.props.visitor}
                        videoTemplates={this.props.videoTemplates}
                        buttonText="Save"
                        handleDelete={this.handleDelete}
                    />
                </Collapse>
            </li>
        )
    }
}

export default Visitor;

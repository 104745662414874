export const USERS_FETCH_SUCCEEDED = 'USERS_FETCH_SUCCEEDED';
export const USERS_FETCH_REQUESTED = 'USERS_FETCH_REQUESTED';
export const USER_CREATE_SUCCEEDED = 'USER_CREATE_SUCCEEDED';
export const USER_UPDATE_SUCCEEDED = 'USER_UPDATE_SUCCEEDED';
export const USER_DELETE_SUCCEEDED = 'USER_DELETE_SUCCEEDED';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';

export function usersFetchRequested(){
    return {
        type: USERS_FETCH_REQUESTED,
    }
}

export function usersFetchSucceeded(users) {
    return {
        type: USERS_FETCH_SUCCEEDED,
        users
    }
}

export function userCreateSucceeded(user) {
    return {
        type: USER_CREATE_SUCCEEDED,
        user
    }
}

export function userUpdateSucceeded(user) {
    return {
        type: USER_UPDATE_SUCCEEDED,
        user
    }
}

export function userDeleteSucceeded(user) {
    return {
        type: USER_DELETE_SUCCEEDED,
        user
    }
}

export function userCreate(user) {
    return {
        type: USER_CREATE,
        user
    }
}

export function userUpdate(user) {
    return {
        type: USER_UPDATE,
        user
    }
}

export function userDelete(user) {
    return {
        type: USER_DELETE,
        user
    }
}